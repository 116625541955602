<template>
  <div class="app-main">
    <div class="xq_banner"><img src="@/assets/img/banner1.png"></div>
    <div class="app-container">
      <div class="position">
        <h2>联系我们</h2>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>联系我们</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="Contact">
        <el-row :gutter="24" >
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <img class="img" src="@/assets/img/download.jpg" alt="">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div class="content">
              <h6>大医精方</h6>
              <span>电话：400-123-4567</span>
              <span>手机：138 0000 0000</span>
              <span>地址：河北省秦皇岛市海港区湘江道8号大医精方总部</span>
              <div>
                <img src="@/assets/img/qiant.jpg" alt="">
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.Contact{
  margin: 20px 0;
  .img{
    width: 100%;
    margin-bottom: 20px;
  }
  .content{
    width: 100%;
    h6{
      font-size: 18px;
      margin-bottom: 10px;
    }
    span{
      font-size: 16px;
      display: block;
      margin-bottom: 8px;
    }
    img{
      width: 250px;
    }
  }
}
</style>